import { Flex, Typography } from '@components/atoms'
import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import Textarea from 'react-textarea-autosize'
import { pixelTrack } from '@utils/facebook/pixel'
import styled from 'styled-components'

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 6px 0px;

  > span:first-child {
    padding-right: 16px;
    width: 10%;
  }
  a {
    margin-top: -3px;
  }
`

const InfoList = styled.div`
  width: 90%;
  word-break: break-word;
`

const TrackInfo = ({ lyrics, composer, lyricst, arranger, genre, track }) => {
  const [more, setMore] = useState(false) // 더보기 유무

  return (
    <>
      {/* 음악 정보 */}
      <Flex type="column" style={{ padding: '20px 0px 0px 0px' }}>
        <Typography type="Medium" size="20px" style={{ paddingBottom: '6px' }}>
          가사
        </Typography>
        <Flex style={{ justifyContent: 'space-between' }}>
          {lyrics ? (
            <Flex type="column" style={{ width: '100%' }}>
              <Textarea
                readOnly
                value={lyrics}
                className="textarea"
                style={{
                  width: '100%',
                  fontSize: '14px',
                  lineHeight: '28px',
                }}
                minRows={1}
                maxRows={more ? 1000 : 5}
              />
              {!more && (
                <Typography
                  onClick={() => {
                    setMore(true)
                    pixelTrack(PIXEL_EVENTS.트랙.가사_더보기, {
                      trackId: track._id,
                      trackTitle: track.title,
                      artistId: track.artistId,
                      artistName: track.releaseArtistList?.[0]?.name,
                    })
                  }}
                  type="Medium"
                  size="12px"
                  color="#949494"
                  style={{ alignSelf: 'flex-end', cursor: 'pointer' }}
                >
                  더보기
                </Typography>
              )}
            </Flex>
          ) : (
            <Typography type="Medium" size="12px">
              등록된 가사가 없습니다.
            </Typography>
          )}
        </Flex>
      </Flex>
      <Flex type="column" style={{ padding: '20px 0px' }}>
        <Typography type="Medium" size="20px" style={{ paddingBottom: '6px' }}>
          Credit
        </Typography>
        {lyricst || composer || arranger || genre ? (
          <>
            <Info>
              <Typography size="14px" color="#646464">
                작사
              </Typography>
              <InfoList>
                {lyricst &&
                  lyricst.map((list, index) =>
                    !list.isAuto ? (
                      <Link to={`/artist/${list.uniqueName}`} key={list._id}>
                        <Typography type="Bold" size="14px" color="#242424">
                          {list.name}
                          {!(index === lyricst.length - 1) && `,\u00A0`}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography size="14px" key={list._id}>
                        {list.name}
                        {!(index === lyricst.length - 1) && `,\u00A0`}
                      </Typography>
                    ),
                  )}
              </InfoList>
            </Info>
            <Info>
              <Typography size="14px" color="#646464">
                작곡
              </Typography>
              <InfoList>
                {composer &&
                  composer.map((list, index) =>
                    !list.isAuto ? (
                      <Link to={`/artist/${list.uniqueName}`} key={list._id}>
                        <Typography type="Bold" size="14px" color="#242424">
                          {list.name}
                          {!(index === composer.length - 1) && `,\u00A0`}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography size="14px" key={list._id}>
                        {list.name}
                        {!(index === composer.length - 1) && `,\u00A0`}
                      </Typography>
                    ),
                  )}
              </InfoList>
            </Info>
            <Info>
              <Typography size="14px" color="#646464">
                편곡
              </Typography>
              <InfoList>
                {arranger &&
                  arranger.map((list, index) =>
                    !list.isAuto ? (
                      <Link to={`/artist/${list.uniqueName}`} key={list._id}>
                        <Typography type="Bold" size="14px" color="#242424">
                          {list.name}
                          {!(index === arranger.length - 1) && `,\u00A0`}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography size="14px" key={list._id}>
                        {list.name}
                        {!(index === arranger.length - 1) && `,\u00A0`}
                      </Typography>
                    ),
                  )}
              </InfoList>
            </Info>
            <Info>
              <Typography size="14px" color="#646464">
                장르
              </Typography>
              <Typography size="14px">{genre}</Typography>
            </Info>
          </>
        ) : (
          '등록된 정보가 없습니다.'
        )}
      </Flex>
    </>
  )
}

export default TrackInfo
