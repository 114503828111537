import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './dots.css'

import { ArtistTabs, CustomTab } from '@components/molecules'
import { Flex, H6, Typography } from '@components/atoms'
import React, { useEffect, useState } from 'react'
import { observer, useObserver } from 'mobx-react'
import styled, { keyframes } from 'styled-components'

import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import ReactLoading from 'react-loading'
import Slider from 'react-slick'
import { pixelTrack } from '@utils/facebook/pixel'
import { useStore } from '@utils/hooks'
import ArtistVideoList from '../List/ArtistVideoList'
import { ArtistAlbumList, ArtistSongList } from '..'

const rightFade = keyframes`
0% {
  opacity: 0;
  transform: translateX(100%);
}
100% {
  opacity: 1;
  transform: translateX(0);
}
`

const leftFade = keyframes`
0% {
  opacity: 0;
  transform: translateX(-100%);
}
100% {
  opacity: 1;
  transform: translateX(0);
}
`

const TabAni = styled.div`
  animation: ${props => (props.arrow === 'left' ? leftFade : rightFade)} 500ms
    ease-out;
  opacity: 1;
`

const ArtistInfo = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [tabAniDirection, setDirection] = useState('right')

  const [videoType, setVideoType] = useState('total')
  const [videoSortBy, setVideoSortBy] = useState('youtubeVideoInfo.viewCount')
  const [videoOffset, setVideoOffset] = useState(0)

  const [trackType, setTrackType] = useState('total')
  const [trackSortBy, setTrackSortBy] = useState('likeCount')
  const [trackOffset, setTrackOffset] = useState(0)
  const [albumType, setAlbumType] = useState('total')
  const [albumSortBy, setAlbumSortBy] = useState('likeCount')
  const [albumOffset, setAlbumOffset] = useState(0)

  const { artistStore } = useStore()

  const handleTrackSortBy = value => {
    setTrackOffset(0)
    setTrackSortBy(value)
  }

  const handleAlbumSortBy = value => {
    setAlbumOffset(0)
    setAlbumSortBy(value)
  }

  const handleVideoSortBy = value => {
    setVideoOffset(0)
    setVideoSortBy(value)
  }

  const handleTrackType = value => {
    setTrackOffset(0)
    setTrackType(value)

    if (value === 'total') {
      pixelTrack(PIXEL_EVENTS?.아티스트?.트랙_전체, {
        artistId: artistStore.artistDetail._id,
        artistName: artistStore.artistDetail.name,
      })
    }
    if (value === 'release') {
      pixelTrack(PIXEL_EVENTS?.아티스트?.트랙_발매, {
        artistId: artistStore.artistDetail._id,
        artistName: artistStore.artistDetail.name,
      })
    }
    if (value === 'participate') {
      pixelTrack(PIXEL_EVENTS?.아티스트?.트랙_참여, {
        artistId: artistStore.artistDetail._id,
        artistName: artistStore.artistDetail.name,
      })
    }
  }

  const handleVideoType = value => {
    setVideoOffset(0)
    setVideoType(value)

    if (value === 'total') {
      pixelTrack(PIXEL_EVENTS?.아티스트?.영상_전체, {
        artistId: artistStore.artistDetail._id,
        artistName: artistStore.artistDetail.name,
      })
    }
    if (value === 'release') {
      pixelTrack(PIXEL_EVENTS?.아티스트?.영상_발매, {
        artistId: artistStore.artistDetail._id,
        artistName: artistStore.artistDetail.name,
      })
    }

    if (value === 'participate') {
      pixelTrack(PIXEL_EVENTS?.아티스트?.영상_참여, {
        artistId: artistStore.artistDetail._id,
        artistName: artistStore.artistDetail.name,
      })
    }
  }

  const handleAlbumType = value => {
    setAlbumOffset(0)
    setAlbumType(value)

    if (value === 'total') {
      pixelTrack(PIXEL_EVENTS?.아티스트?.앨범_전체, {
        artistId: artistStore.artistDetail._id,
        artistName: artistStore.artistDetail.name,
      })
    }
    if (value === 'release') {
      pixelTrack(PIXEL_EVENTS?.아티스트?.앨범_발매, {
        artistId: artistStore.artistDetail._id,
        artistName: artistStore.artistDetail.name,
      })
    }
    if (value === 'participate') {
      pixelTrack(PIXEL_EVENTS?.아티스트?.앨범_참여, {
        artistId: artistStore.artistDetail._id,
        artistName: artistStore.artistDetail.name,
      })
    }
  }

  useEffect(() => {
    if (artistStore.artistDetail && artistStore.artistDetail.totalVideoList) {
      artistStore.fetchAritstVideoList(artistStore.artistDetail._id, {
        sortBy: videoSortBy,
        type: videoType,
        offset: videoOffset,
      })
    }
  }, [artistStore.artistDetail, videoType, videoSortBy, videoOffset])

  useEffect(() => {
    if (artistStore.artistDetail && artistStore.artistDetail.releaseTrackList) {
      artistStore.fetchAritstTrackList(artistStore.artistDetail._id, {
        sortBy: trackSortBy,
        type: trackType,
        offset: trackOffset,
      })
    }
  }, [artistStore.artistDetail, trackType, trackSortBy, trackOffset])

  useEffect(() => {
    if (artistStore.artistDetail && artistStore.artistDetail.releaseAlbumList) {
      artistStore.fetchAritstAlbumList(artistStore.artistDetail._id, {
        sortBy: albumSortBy,
        type: albumType,
        offset: albumOffset,
      })
    }
  }, [artistStore.artistDetail, albumType, albumSortBy, albumOffset])

  // useEffect(() => {
  //   if (
  //     !artistStore.isListLoading &&
  //     artistStore.artistDetail?.totalVideoList?.length === 0
  //   ) {
  //     setSelectedIndex(1)
  //   }
  // }, [artistStore.artistDetail?.totalVideoList])

  return (
    <ArtistTabs
      selectedIndex={selectedIndex}
      onSelect={idx => {
        if (selectedIndex < idx) {
          setDirection('right')
        } else {
          setDirection('left')
        }
        setSelectedIndex(idx)
        if (idx === 0) {
          pixelTrack(PIXEL_EVENTS?.아티스트?.탭_트랙, {
            artistId: artistStore.artistDetail._id,
            artistName: artistStore.artistDetail.name,
          })
        } else if (idx === 1) {
          pixelTrack(PIXEL_EVENTS?.아티스트?.탭_앨범, {
            artistId: artistStore.artistDetail._id,
            artistName: artistStore.artistDetail.name,
          })
        } else if (idx === 2) {
          pixelTrack(PIXEL_EVENTS?.아티스트?.탭_영상, {
            artistId: artistStore.artistDetail._id,
            artistName: artistStore.artistDetail.name,
          })
        }
      }}
      menu={['트랙', '앨범', '영상']}
      contents={[
        <TabAni arrow={tabAniDirection}>
          {artistStore.isListLoading && (
            <Flex
              style={{
                position: 'relative',
                width: '100%',
                height: '100vh',
                justifyContent: 'center',
              }}
            >
              <ReactLoading
                type="spin"
                color="black"
                style={{
                  width: '50px',
                  height: '50px',
                  margin: '100px auto 50px auto',
                  position: 'absolute',
                }}
              />
            </Flex>
          )}
          <Flex style={{ width: '100%' }} type="column">
            <Flex>
              <H6
                onClick={() => handleTrackType('total')}
                color={trackType === 'total' ? '#222222' : '#c9c9c9'}
                style={{ cursor: 'pointer', marginRight: '10px' }}
              >
                {`전체(${(artistStore.artistDetail &&
                  artistStore.artistDetail.totalTrackCount) ||
                  0})`}
              </H6>
              <H6
                onClick={() => handleTrackType('release')}
                color={trackType === 'release' ? '#222222' : '#c9c9c9'}
                style={{ cursor: 'pointer', marginRight: '10px' }}
              >
                {`발매(${(artistStore.artistDetail &&
                  artistStore.artistDetail.releaseTrackCount) ||
                  0})`}
              </H6>
              <H6
                onClick={() => handleTrackType('participate')}
                color={trackType === 'participate' ? '#222222' : '#c9c9c9'}
                style={{ cursor: 'pointer', marginRight: '10px' }}
              >
                {`참여(${(artistStore.artistDetail &&
                  artistStore.artistDetail.participationTrackCount) ||
                  0})`}
              </H6>
            </Flex>

            {trackType === 'total' ? (
              !!artistStore.artistDetail &&
              artistStore.artistDetail.totalTrackList?.length > 0 ? (
                <ArtistSongList
                  list={
                    artistStore.artistDetail &&
                    artistStore.artistDetail?.totalTrackList
                  }
                  sortBy={trackSortBy}
                  setSortBy={handleTrackSortBy}
                  offset={trackOffset}
                  setOffset={setTrackOffset}
                  maxCount={
                    artistStore.artistDetail &&
                    artistStore.artistDetail.totalTrackCount
                  }
                />
              ) : (
                <Typography style={{ marginTop: '20px' }}>
                  발매한 곡이 없습니다.
                </Typography>
              )
            ) : trackType === 'release' ? (
              !!artistStore.artistDetail &&
              artistStore.artistDetail.releaseTrackList.length > 0 ? (
                <ArtistSongList
                  list={
                    artistStore.artistDetail &&
                    artistStore.artistDetail.releaseTrackList
                  }
                  sortBy={trackSortBy}
                  setSortBy={handleTrackSortBy}
                  offset={trackOffset}
                  setOffset={setTrackOffset}
                  maxCount={
                    artistStore.artistDetail &&
                    artistStore.artistDetail.releaseTrackCount
                  }
                />
              ) : (
                <Typography style={{ marginTop: '20px' }}>
                  발매한 곡이 없습니다.
                </Typography>
              )
            ) : !!artistStore.artistDetail &&
              artistStore.artistDetail.participationTrackList.length > 0 ? (
              <ArtistSongList
                list={
                  artistStore.artistDetail &&
                  artistStore.artistDetail.participationTrackList
                }
                sortBy={trackSortBy}
                setSortBy={handleTrackSortBy}
                offset={trackOffset}
                setOffset={setTrackOffset}
                maxCount={
                  artistStore.artistDetail &&
                  artistStore.artistDetail.participationTrackCount
                }
              />
            ) : (
              <Typography style={{ marginTop: '20px' }}>
                참여한 곡이 없습니다.
              </Typography>
            )}
          </Flex>
        </TabAni>,
        <TabAni arrow={tabAniDirection}>
          {artistStore.isListLoading && (
            <Flex
              style={{
                position: 'relative',
                width: '100%',
                height: '100vh',
                justifyContent: 'center',
              }}
            >
              <ReactLoading
                type="spin"
                color="black"
                style={{
                  width: '50px',
                  height: '50px',
                  margin: '100px auto 50px auto',
                  position: 'absolute',
                }}
              />
            </Flex>
          )}
          <Flex style={{ width: '100%' }} type="column">
            <Flex>
              <H6
                onClick={() => handleAlbumType('total')}
                color={albumType === 'total' ? '#222222' : '#c9c9c9'}
                style={{ cursor: 'pointer', marginRight: '10px' }}
              >
                {`전체(${(artistStore.artistDetail &&
                  artistStore.artistDetail.totalAlbumCount) ||
                  0})`}
              </H6>
              <H6
                onClick={() => handleAlbumType('release')}
                color={albumType === 'release' ? '#222222' : '#c9c9c9'}
                style={{ cursor: 'pointer', marginRight: '10px' }}
              >
                {`발매(${(artistStore.artistDetail &&
                  artistStore.artistDetail.releaseAlbumCount) ||
                  0})`}
              </H6>
              <H6
                onClick={() => handleAlbumType('participate')}
                color={albumType === 'participate' ? '#222222' : '#c9c9c9'}
                style={{ cursor: 'pointer', marginRight: '10px' }}
              >
                {`참여(${(artistStore.artistDetail &&
                  artistStore.artistDetail.participationAlbumCount) ||
                  0})`}
              </H6>
            </Flex>

            {albumType === 'total' ? (
              !!artistStore.artistDetail &&
              artistStore.artistDetail?.totalAlbumList?.length > 0 ? (
                <ArtistAlbumList
                  list={
                    artistStore.artistDetail &&
                    artistStore.artistDetail?.totalAlbumList
                  }
                  sortBy={albumSortBy}
                  setSortBy={handleAlbumSortBy}
                  offset={albumOffset}
                  setOffset={setAlbumOffset}
                  maxCount={
                    artistStore.artistDetail &&
                    artistStore.artistDetail.totalAlbumCount
                  }
                />
              ) : (
                <Typography style={{ marginTop: '20px' }}>
                  발매한 앨범이 없습니다.
                </Typography>
              )
            ) : albumType === 'release' ? (
              !!artistStore.artistDetail &&
              artistStore.artistDetail.releaseAlbumList.length > 0 ? (
                <ArtistAlbumList
                  list={
                    artistStore.artistDetail &&
                    artistStore.artistDetail.releaseAlbumList
                  }
                  sortBy={albumSortBy}
                  setSortBy={handleAlbumSortBy}
                  offset={albumOffset}
                  setOffset={setAlbumOffset}
                  maxCount={
                    artistStore.artistDetail &&
                    artistStore.artistDetail.releaseAlbumCount
                  }
                />
              ) : (
                <Typography style={{ marginTop: '20px' }}>
                  발매한 앨범이 없습니다.
                </Typography>
              )
            ) : !!artistStore.artistDetail &&
              artistStore.artistDetail.participationAlbumList.length > 0 ? (
              <ArtistAlbumList
                list={
                  artistStore.artistDetail &&
                  artistStore.artistDetail.participationAlbumList
                }
                sortBy={albumSortBy}
                setSortBy={handleAlbumSortBy}
                offset={albumOffset}
                setOffset={setAlbumOffset}
                maxCount={
                  artistStore.artistDetail &&
                  artistStore.artistDetail.participationAlbumCount
                }
              />
            ) : (
              <Typography style={{ marginTop: '20px' }}>
                참여한 앨범이 없습니다.
              </Typography>
            )}
          </Flex>
        </TabAni>,
        <TabAni arrow={tabAniDirection}>
          {artistStore.isListLoading && (
            <Flex
              style={{
                position: 'relative',
                width: '100%',
                height: '100vh',
                justifyContent: 'center',
              }}
            >
              <ReactLoading
                type="spin"
                color="black"
                style={{
                  width: '50px',
                  height: '50px',
                  margin: '100px auto 50px auto',
                  position: 'absolute',
                }}
              />
            </Flex>
          )}
          <Flex style={{ width: '100%' }} type="column">
            <Flex>
              <H6
                onClick={() => handleVideoType('total')}
                color={videoType === 'total' ? '#222222' : '#c9c9c9'}
                style={{ cursor: 'pointer', marginRight: '10px' }}
              >
                {`전체(${(artistStore.artistDetail &&
                  artistStore.artistDetail.totalVideoCount) ||
                  0})`}
              </H6>
              <H6
                onClick={() => handleVideoType('release')}
                color={videoType === 'release' ? '#222222' : '#c9c9c9'}
                style={{ cursor: 'pointer', marginRight: '10px' }}
              >
                {`발매2(${(artistStore.artistDetail &&
                  artistStore.artistDetail.releaseVideoCount) ||
                  0})`}
              </H6>
              <H6
                onClick={() => handleVideoType('participate')}
                color={videoType === 'participate' ? '#222222' : '#c9c9c9'}
                style={{ cursor: 'pointer', marginRight: '10px' }}
              >
                {`참여(${(artistStore.artistDetail &&
                  artistStore.artistDetail.participateVideoCount) ||
                  0})`}
              </H6>
            </Flex>

            {videoType === 'total' ? (
              !!artistStore.artistDetail &&
              artistStore.artistDetail.totalVideoList?.length > 0 ? (
                <ArtistVideoList
                  list={
                    artistStore.artistDetail &&
                    artistStore.artistDetail?.totalVideoList
                  }
                  sortBy={videoSortBy}
                  setSortBy={handleVideoSortBy}
                  offset={videoOffset}
                  setOffset={setVideoOffset}
                  maxCount={
                    artistStore.artistDetail &&
                    artistStore.artistDetail.totalVideoCount
                  }
                />
              ) : (
                <Typography style={{ marginTop: '20px', color: '#949494' }}>
                  동영상이 없습니다.
                </Typography>
              )
            ) : videoType === 'release' ? (
              !!artistStore.artistDetail &&
              artistStore.artistDetail.releaseVideoList.length > 0 ? (
                <ArtistVideoList
                  list={
                    artistStore.artistDetail &&
                    artistStore.artistDetail.releaseVideoList
                  }
                  sortBy={videoSortBy}
                  setSortBy={handleVideoSortBy}
                  offset={videoOffset}
                  setOffset={setVideoOffset}
                  maxCount={
                    artistStore.artistDetail &&
                    artistStore.artistDetail.releaseVideoCount
                  }
                />
              ) : (
                <Typography style={{ marginTop: '20px', color: '#949494' }}>
                  동영상이 없습니다.
                </Typography>
              )
            ) : !!artistStore.artistDetail &&
              artistStore.artistDetail.participationVideoList.length > 0 ? (
              <ArtistVideoList
                list={
                  artistStore.artistDetail &&
                  artistStore.artistDetail.participationVideoList
                }
                sortBy={videoSortBy}
                setSortBy={handleVideoSortBy}
                offset={videoOffset}
                setOffset={setVideoOffset}
                maxCount={
                  artistStore.artistDetail &&
                  artistStore.artistDetail.participateVideoCount
                }
              />
            ) : (
              <Typography style={{ marginTop: '20px', color: '#949494' }}>
                동영상이 없습니다.
              </Typography>
            )}
          </Flex>
        </TabAni>,
      ]}
    />
  )
}

export default observer(ArtistInfo)
