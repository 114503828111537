export const PIXEL_EVENTS = {
  아티스트: {
    옵션: '아티스트_옵션',
    공유: '아티스트_공유',
    좋아요: '아티스트_좋아요',
    홈페이지: '아티스트_홈페이지',
    인스타그램: '아티스트_인스타그램',
    트위터: '아티스트_트위터',
    위버스: '아티스트_위버스',
    유튜브: '아티스트_유튜브',
    사운드클라우드: '아티스트_사운드클라우드',
    틱톡: '아티스트_틱톡',
    페이스북: '아티스트_페이스북',
    이메일: '아티스트_이메일',
    기타소셜: '아티스트_기타소셜',
    스마트링크: '아티스트_스마트링크',

    팬스팟: '아티스트_팬스팟',
    팬스팟_첫글: '아티스트_팬스팟_첫글',
    팬스팟_게시글: '아티스트_팬스팟_게시글',

    스포티파이: '아티스트_스포티파이',
    유튜브뮤직: '아티스트_유튜브뮤직',
    멜론: '아티스트_멜론',
    지니: '아티스트_지니',
    벅스: '아티스트_벅스',
    바이브: '아티스트_바이브',
    플로: '아티스트_플로',
    애플뮤직: '아티스트_애플뮤직',
    아마존뮤직: '아티스트_아마존뮤직',
    타이달: '아티스트_타이달',

    탭_트랙: '아티스트_탭_트랙',
    탭_앨범: '아티스트_탭_앨범',
    탭_영상: '아티스트_탭_영상',
    트랙_전체: '아티스트_트랙_전체',
    트랙_발매: '아티스트_트랙_발매',
    트랙_참여: '아티스트_트랙_참여',
    트랙_인기순: '아티스트_트랙_인기순',
    트랙_최신순: '아티스트_트랙_최신순',
    트랙_이름순: '아티스트_트랙_이름순',
    트랙_아이템: '아티스트_트랙_아이템',
    앨범_전체: '아티스트_앨범_전체',
    앨범_발매: '아티스트_앨범_발매',
    앨범_참여: '아티스트_앨범_참여',
    앨범_인기순: '아티스트_앨범_인기순',
    앨범_최신순: '아티스트_앨범_최신순',
    앨범_이름순: '아티스트_앨범_이름순',
    앨범_아이템: '아티스트_앨범_아이템',
    영상_전체: '아티스트_영상_전체',
    영상_발매: '아티스트_영상_발매',
    영상_참여: '아티스트_영상_참여',
    영상_인기순: '아티스트_영상_인기순',
    영상_최신순: '아티스트_영상_최신순',
    영상_이름순: '아티스트_영상_이름순',
    영상_아이템: '아티스트_영상_아이템',
  },
  트랙: {
    옵션: '트랙_옵션',
    앨범링크: '트랙_앨범링크',
    아티스트링크1: '트랙_아티스트링크',
    공유: '트랙_공유',
    좋아요: '트랙_좋아요',

    유튜브재생: '트랙_유튜브재생',
    유튜브_다음영상: '트랙_유튜브_다음영상',

    스포티파이: '트랙_스포티파이',
    유튜브뮤직: '트랙_유튜브뮤직',
    멜론: '트랙_멜론',
    지니: '트랙_지니',
    벅스: '트랙_벅스',
    바이브: '트랙_바이브',
    플로: '트랙_플로',
    애플뮤직: '트랙_애플뮤직',
    아마존뮤직: '트랙_아마존뮤직',
    타이달: '트랙_타이달',
    디저: '트랙_디저',
    라인뮤직: '트랙_라인뮤직',

    가사_더보기: '트랙_가사_더보기',
    아티스트링크2: '트랙_아티스트링크2',
    댓글목록: '트랙_댓글목록',
  },
}
