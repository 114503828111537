import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { H5, H6, Caption1, Caption2, Flex } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { open_alarm_talk_img, check_empty_img, check_img_blue } from '@images/'
import { colors } from '@colors/'
import { arrow_down, check_img } from '@images/index'
import { useSnackBar } from '..'

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({
  isShowing,
  toggle,
  onCancel,
  updatedOpen,
  requestOpen,
  toggleComplte,
}) => {
  const [isShowPrivacy, setIsShowPrivacy] = useState(false)
  const [isCheck, setIsCheck] = useState(false)
  const [emailVal, setEmailVal] = useState('')
  const { toggleSnackBar, View: SnackBar } = useSnackBar()
  const {
    View: VallidationView,
    toggleSnackBar: handleVallidation,
  } = useSnackBar()

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
    toggle()
  }

  const validateEmail = value => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(value)
  }

  const handleOk = async () => {
    if (!isCheck) {
      toggleSnackBar()
      return
    }

    if (!validateEmail(emailVal)) {
      handleVallidation()
      return
    }

    if (requestOpen && updatedOpen) {
      const requestResult = await requestOpen()
      if (requestResult) {
        const res = await updatedOpen(emailVal, requestResult)
        toggle()
        toggleComplte()
      }
    }
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <SnackBar
          text={'개인정보 수집 이용에 동의해주세요'}
          backgroundColor="#ea4653"
        />
        <VallidationView
          text={'이메일 형식을 확인해주세요'}
          backgroundColor="#ea4653"
        />
        <ModalOverlay />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <Flex type={'column'} style={{ padding: '0px 20px' }}>
                <H5 style={{ marginTop: 24 }}>오픈 알림</H5>
                <img
                  src={open_alarm_talk_img}
                  alt="open_icon"
                  style={
                    {
                      width: 48,
                      height: 48,
                      margin: '16px 0',
                      alignSelf: 'center',
                    }
                  }
                />
                <Caption1 align="center">
                    아티스트 팬스팟 오픈을 요청하세요!
                </Caption1>
                <Flex type="column" style={{ margin: '8px 0 16px 0' }}>
                  <Caption1
                    type="Regular"
                    align="center"
                    color={colors.brownish_grey}
                  >
                      이메일 주소를 입력하시면 팬스팟 오픈 시
                  </Caption1>
                  <Caption1
                    type="Regular"
                    align="center"
                    color={colors.brownish_grey}
                  >
                      이메일로 안내 메시지를 보내드립니다.
                  </Caption1>
                </Flex>
                <EmailInput
                  placeholder="이메일을 입력해주세요"
                  type="email"
                  style={{ marginBottom: 16 }}
                  value={emailVal}
                  onChange={e => setEmailVal(e.target.value)}
                  required
                />
              </Flex>
              <Flex
                align={'center'}
                justify={'space-between'}
                style={{ padding: '12px 20px' }}
              >
                <Flex
                  onClick={() => setIsCheck(!isCheck)}
                  style={{ cursor: 'pointer' }}
                  align={'center'}
                >
                  <img
                    src={check_img}
                    alt="checkbox_icon"
                    style={
                      {
                        width: 24,
                        height: 24,
                        marginRight: 8,
                        opacity: isCheck ? 1 : 0.5,
                      }
                    }
                  />
                  <Caption1>
                    <Caption1 type={'Regular'} color={'#949494'}>
                        (필수)
                    </Caption1>
                    {' '}
                      개인정보 수집 이용에 동의합니다.
                  </Caption1>
                </Flex>
                <Flex
                  style={
                    {
                      cursor: 'pointer',
                    }
                  }
                  onClick={
                    () => {
                      setIsShowPrivacy(val => !val)
                    }
                  }
                >
                  <img
                    src={arrow_down}
                    alt={'arrow_img'}
                    style={
                      {
                        width: '16px',
                        height: '16px',
                        rotate: isShowPrivacy ? '180deg' : '360deg',
                      }
                    }
                  />
                </Flex>
              </Flex>
              {
                isShowPrivacy && (
                  <Flex
                    type={'column'}
                    style={{ background: '#FAFAFA', padding: '16px 20px' }}
                  >
                    <Caption2 align="left" style={{ marginBottom: '6px' }}>
                      개인정보 수집 이용 동의
                    </Caption2>
                    <Caption2 align="left" type="Regular">
                      플램은 이용자가 신청하신 서비스의 오픈 알림을 위해
                      <br />
                      ‘이메일’을 수집 이용하며, 오픈 알림 후 파기됩니다.
                      <br />
                      개인정보 수집 이용 동의를 거부하실 수 있으며, 거부 시
                      <br />
                      팬스팟 오픈 알림이 어렵습니다.
                    </Caption2>
                  </Flex>
                )
              }
              {/* footer */}
              <Flex style={{ justifyContent: 'flex-end', marginTop: '30px' }}>
                <CancelBtn onClick={handleCancel}>
                  <H6>취소</H6>
                </CancelBtn>
                <Okbtn onClick={handleOk} disabled={!isCheck}>
                  <H6>오픈 요청</H6>
                </Okbtn>
              </Flex>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  usePopup,
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background: white;
  overflow: auto;
  max-width: 380px;
  min-width: 280px;
  width: calc(100% - 40px);
  /* min-height: 474px; */
  height: fit-content;
  top: 40%;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 20px; */
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const EmailInput = styled.input`
  /* width: calc(100% - 40px); */
  /* height: 48px; */
  font-size: 12px;
  font-family: NotoSansCJKkr-Regular;
  border: solid 0.5px #d4d4d4;
  border-radius: 2px;

  background: #fff;
  padding: 11.5px 12px;
  margin: 0px;
`

const CancelBtn = styled.button`
  display: flex;
  height: 44px;
  padding: 5.5px 13px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  font-size: 14px;
  font-family: NotoSansCJKkr-Regular;
  border: none;
  background: var(--grey-grey_40, #d4d4d4);
  color: #646464;
  cursor: pointer;
`

const Okbtn = styled.button`
  display: flex;
  height: 44px;
  padding: 5.5px 13px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  font-size: 14px;
  font-family: NotoSansCJKkr-Regular;
  border: none;
  border-radius: 0px 0px 2px 0px;
  background: var(--grey-grey_80, #242424);
  cursor: pointer;
  color: var(--grey-grey_10, #fafafa);
  opacity: ${props => (props.disabled ? 0.16 : 1)};
`
