import { BodyWrapper, Caption1, Caption2, Flex, H4 } from '@components/atoms'
import {
  BurgerMenu,
  CommentInput,
  LikePopup,
  LoginPopup,
  StreamingList,
  TrackCard,
  TrackGenieBugsTableChart,
  TrackInfo,
  TrackMelonTableChart,
  TrackSnsLineChart,
  TrackYoutubeTableChart,
} from '@components/organisms'
import React, { useEffect, useState } from 'react'
import { dateYMD, dateYMDMM } from '@utils/format'

import { DetailCoverImage } from '@components/molecules'
import { Helmet } from 'react-helmet'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import { STORAGE_URL } from '@consts/'
import { default_music_img } from '@images'
import { observer } from 'mobx-react'
import { pixelTrack } from '@utils/facebook/pixel'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useStore } from '@utils/hooks'

const BackWrapper = styled.div`
  background-color: rgb(36, 36, 36);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 202px;
`

const BackColor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 202px;
  background-image: url(${({ background }) => background && `'${background}'`});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0px -110px;
  opacity: 0.2;
`

const TrackTemplate = ({
  track,
  currentPath,
  updateRecord,
  currentUser,
  logout,
  fetchLike,
  likeInfo,
  updateLike,
  commentList,
  commentListCount,
  isPlayFirstYoutube,
}) => {
  const history = useHistory()

  const { trendStore, authStore } = useStore()

  const artistTrackTrend = trendStore?.artistTrackTrend
  const fetchArtistTrackTrend = trendStore?.fetchArtistTrackTrend || (() => {})
  // const isLoginedArtist = (authStore?.currentUser?.type === 'artist' || authStore?.currentUser?.type === 'company') || false
  const isLogined = !!authStore?.currentUser || false

  const [isShowLoginPopup, setIsShowLoginPopup] = useState(false) // 로그인 상태
  const [showLikePopup, setShowLikePopup] = useState(false) // 좋아요 알림 팝업

  const [isOpen, setIsOpen] = useState(false) // 햄버거

  const [isDetailCover, setIsDetailCover] = useState(false) // 상세 이미지

  useEffect(() => {
    if (track && !currentUser) {
      fetchLike(track._id)
    } else if (track && currentUser) {
      fetchLike(track._id, currentUser._id)
    }
  }, [track, currentUser])

  useEffect(() => {
    if (track)
      fetchArtistTrackTrend(track?.artistId, track._id, { periodDays: 28 })
  }, [track, fetchArtistTrackTrend])

  return (
    <>
      <Helmet>
        <title>{track && `${track.title} | 플램`}</title>
        <meta
          name="description"
          content={`${track &&
            track.releaseArtistList &&
            track.releaseArtistList[0] &&
            track.releaseArtistList[0].name}의 음악에 한걸음 더, 플램`}
        />
      </Helmet>
      <BurgerMenu
        isOpen={isOpen}
        setIsOpen={value => {
          setIsOpen(value)
          pixelTrack(PIXEL_EVENTS?.트랙?.옵션, {
            trackId: track?._id,
            trackTitle: track?.title,
            artistName: track?.releaseArtistList[0]?.name,
            artistId: track?.artistId,
          })
        }}
        pageWrapId={'track_template'}
        outerContainerId={'App'}
        pageTitle={track && track.title}
      />
      <BodyWrapper
        id="track_template"
        style={{ minHeight: '100vh', paddingBottom: 90 }}
      >
        {isShowLoginPopup && (
          <LoginPopup handleClose={() => setIsShowLoginPopup(false)} />
        )}
        {showLikePopup && !authStore?.isNeverShowLikeTrackPopup && (
          <LikePopup
            handleClose={() => setShowLikePopup(false)}
            type={'track'}
          />
        )}
        <BackWrapper>
          <BackColor
            background={
              (track && track.albumInfo.image128Uri) || default_music_img
            }
          />
        </BackWrapper>
        <Flex type="column">
          <TrackCard
            albumTitle={track && track.albumInfo.title}
            albumImg={track && track.albumInfo.image128Uri}
            title={track && track.title}
            artist={track && track.releaseArtistList}
            trackId={track && track._id}
            albumId={track && track.albumInfo._id}
            albumCustomUrlInfo={
              track && track.albumInfo && track.albumInfo.customUrlInfo
            }
            subdomainInfo={track && track.subdomainInfo}
            customUrlInfo={track && track.customUrlInfo}
            currentPath={currentPath}
            currentUser={currentUser}
            setIsShowLoginPopup={setIsShowLoginPopup}
            setShowLikePopup={setShowLikePopup}
            likeInfo={likeInfo}
            updateLike={updateLike}
            setIsDetailCover={setIsDetailCover}
          />
          <StreamingList
            youtubeUrlList={track && track.youtubeUrlList}
            youtube={track && track.youtubeUrl}
            melon={track && track.melonUrl}
            genie={track && track.genieUrl}
            bugs={track && track.bugsUrl}
            flo={track && track.floUrl}
            vibe={track && track.vibeUrl}
            youtubeMusic={track && track.youtubeMusicUrl}
            appleMusic={track && track.appleMusicUrl}
            spotify={track && track.spotifyUrl}
            amazonMusic={track && track.amazonMusicUrl}
            etc={track && track.etcUrl}
            tidalUrl={track?.tidalUrl}
            deezerUrl={track?.deezerUrl}
            jooxUrl={track?.jooxUrl}
            mymusicUrl={track?.mymusicUrl}
            kkboxUrl={track?.kkboxUrl}
            linejpUrl={track?.linejpUrl}
            linetwUrl={track?.linetwUrl}
            yandexUrl={track?.yandexUrl}
            nctUrl={track?.nctUrl}
            zingUrl={track?.zingUrl}
            anghmiUrl={track?.anghmiUrl}
            updateRecord={updateRecord}
            isPlayFirstYoutube={isPlayFirstYoutube}
            track={track}
          />
          <TrackInfo
            lyrics={track && track.lyrics}
            composer={track && track.composerArtistList}
            lyricst={track && track.lyricstArtistList}
            arranger={track && track.arrangerArtistList}
            genre={track && track.mainGenre}
            subGenre={track && track.subGenre}
            track={track}
          />
          {isLogined && currentUser?.type === 'company' ? (
            <Flex type="column">
              <div
                style={{
                  marginTop: '24px',
                  height: 6,
                  backgroundColor: '#fafafa',
                  width: '100vw',
                  marginLeft: -16,
                  borderTop: '1px solid #f4f4f4',
                }}
              />
              <Flex
                justify="space-between"
                align="flex-end"
                style={{ margin: '12px 0 24px 0' }}
              >
                <H4>데이터</H4>
                <Caption1 color="#646464">
                  {artistTrackTrend?.trendDataAddedAt
                    ? dateYMD(artistTrackTrend?.trendDataAddedAt)
                    : '-'}
                </Caption1>
              </Flex>
              <TrackSnsLineChart
                dailyListData={artistTrackTrend?.dailyDetailList || []}
              />
              <Flex
                justify="space-between"
                align="flex-end"
                style={{ margin: '12px 0 24px 0' }}
              >
                <H4>멜론</H4>
                <Caption1 color="#646464">
                  {artistTrackTrend?.trendDataAddedAt
                    ? dateYMD(artistTrackTrend?.trendDataAddedAt)
                    : '-'}
                </Caption1>
              </Flex>
              <TrackMelonTableChart />
              <Button
                onClick={() =>
                  history.push(`/track/${track?._id}/${track?.artistId}/melon`)
                }
              >
                <Caption1 color="#646464">모두 보기</Caption1>
              </Button>
              <div
                style={{
                  marginTop: '24px',
                  height: 6,
                  backgroundColor: '#fafafa',
                  width: '100vw',
                  marginLeft: -16,
                  borderTop: '1px solid #f4f4f4',
                }}
              />
              <Flex
                justify="space-between"
                align="flex-end"
                style={{ margin: '12px 0 24px 0' }}
              >
                <H4>유튜브</H4>
                <Caption1 color="#646464">
                  {artistTrackTrend?.trendDataAddedAt
                    ? dateYMD(artistTrackTrend?.trendDataAddedAt)
                    : '-'}
                </Caption1>
              </Flex>
              <TrackYoutubeTableChart />
              <Button
                onClick={() =>
                  history.push(
                    `/track/${track?._id}/${track?.artistId}/youtube`,
                  )
                }
              >
                <Caption1 color="#646464">모두 보기</Caption1>
              </Button>
              <div
                style={{
                  marginTop: '24px',
                  height: 6,
                  backgroundColor: '#fafafa',
                  width: '100vw',
                  marginLeft: -16,
                  borderTop: '1px solid #f4f4f4',
                }}
              />
              <Flex
                justify="space-between"
                align="flex-end"
                style={{ margin: '12px 0 24px 0' }}
              >
                <H4>지니&벅스</H4>
                <Caption1 color="#646464">
                  {artistTrackTrend?.trendDataAddedAt
                    ? dateYMD(artistTrackTrend?.trendDataAddedAt)
                    : '-'}
                </Caption1>
              </Flex>
              <TrackGenieBugsTableChart />
              <Button
                onClick={() =>
                  history.push(
                    `/track/${track?._id}/${track?.artistId}/genie&bugs`,
                  )
                }
              >
                <Caption1 color="#646464">모두 보기</Caption1>
              </Button>
            </Flex>
          ) : (
            <></>
          )}
        </Flex>
        {isDetailCover && (
          <DetailCoverImage
            setIsDetailCover={setIsDetailCover}
            imgSrc={track.albumInfo.imageUri}
          />
        )}
        <CommentInput
          isLike={likeInfo && likeInfo.isLike}
          dataObjectId={track && track._id}
          dataCollectionName="tracks"
          commentList={commentList}
          commentListCount={commentListCount}
          track={track}
        />
      </BodyWrapper>
    </>
  )
}

export default observer(TrackTemplate)

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 10px;
  border-radius: 2px;
  background-color: #f8f8f8;
  border: none;
  margin-top: 12px;
`
