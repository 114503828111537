import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'

import {
  InPlamArtistOpenTemplate,
  InPlamArtistTemplate,
} from '@components/templates'
import qs from 'qs'
import { DOMAIN_URL } from '@consts/'
import { LoginPopup } from '@components/organisms'

interface InPlamArtistPageProps {
  articleStore: ArticleStore;
  authStore: AuthStore;
  match: Any;
  history: Any;
  location: Any;
}

const InPlamArtistPage = ({
  match,
  location,
  history,
  authStore,
  articleStore,
  inplamStore,
  loadingStore,
}: InPlamArtistPageProps) => {
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  const { isLoading } = loadingStore

  /* store */
  const fetchLike = authStore.fetchLike || (() => {})
  const fetchArtistArticleList =
    articleStore.fetchArtistArticleList || (() => {})
  const fetchPopularArtistArticleList =
    articleStore.fetchPopularArtistArticleList || (() => {})
  const fetchArtistFanSpotPointInfo =
    inplamStore.fetchArtistFanSpotPointInfo || (() => {})
  const fetchInplamByUniqueName =
    inplamStore.fetchInplamByUniqueName || (() => {})

  const currentUser = authStore.currentUser || null
  const likeInfo = authStore.likeInfo || null
  const artistInfo = articleStore.artistInfo || null
  const artistArticleList = articleStore.artistArticleList || null
  const popularArtistArticleList = articleStore.popularArtistArticleList || null
  const artistArticleListCount = articleStore.artistArticleListCount || null
  const fetchArticleCategoryList = articleStore.fetchArticleCategoryList || null
  /* store end */

  const [artistFanSpotPointInfo, setArtistFanSpotPointInfo] = useState(null)
  const [artistInplamInfo, setArtistInplamInfo] = useState(null)

  const [offset, setOffset] = useState(query.offset ? Number(query.offset) : 0)
  const [sortBy, setSortBy] = useState(
    query.sortBy ? query.sortBy : 'createdAt',
  )
  const [categoryList, setCategoryList] = useState([])
  const [category, setCategory] = useState(
    query.category ? query.category : 'all',
  )
  const [isFromArtist, setIsFromArtist] = useState(null) // FromArtist

  const [searchWord, setSearchWord] = useState(
    query.searchWord ? query.searchWord : null,
  )

  const [isShowLoginPopup, setIsShowLoginPopup] = useState(false) // 로그인 상태

  // const toggleFromArtist = _category => {
  //   if (_category === 'artist') {
  //     setIsFromArtist('artist')
  //   }
  //   else {
  //     setIsFromArtist(null)
  //   }
  //   // setOffset(0)
  //   handlePagination(0)
  // }

  // const handlePagination = _offset => {
  //   history.push(
  //     `/fanspot/${match.params.uniqueName}?offset=${_offset}&sortBy=${sortBy}`,
  //   )
  // }

  // const handleChangeSortBy = _sortBy => {
  //   history.push(
  //     `/fanspot/${match.params.uniqueName}?offset=${offset}&sortBy=${_sortBy}`,
  //   )
  // }

  const handlePagination = _offset => {
    if (!searchWord) {
      history.replace(
        `/fanspot/${match.params.uniqueName}?offset=${_offset}&sortBy=${sortBy}&category=${category}`,
      )
    }
    else {
      history.replace(
        `/fanspot/${match.params.uniqueName}?offset=${_offset}&sortBy=${sortBy}&searchWord=${searchWord}&category=${category}`,
      )
    }
  }

  const handleChangeSortBy = _sortBy => {
    if (!searchWord) {
      history.replace(
        `/fanspot/${
          match.params.uniqueName
        }?offset=${0}&sortBy=${_sortBy}&category=${category}`,
      )
    }
    else {
      history.replace(
        `/fanspot/${
          match.params.uniqueName
        }?offset=${0}&sortBy=${_sortBy}&searchWord=${searchWord}&category=${category}`,
      )
    }
  }

  const handleChangeSearchWord = _searchWord => {
    if (!_searchWord) {
      history.replace(
        `/fanspot/${match.params.uniqueName}?offset=${offset}&sortBy=${sortBy}&category=${category}`,
      )
    }
    else {
      history.replace(
        `/fanspot/${match.params.uniqueName}?offset=${offset}&sortBy=${sortBy}&searchWord=${_searchWord}&category=${category}`,
      )
    }
  }

  const handleChangeCategory = _category => {
    if (!searchWord) {
      history.replace(
        `/fanspot/${
          match.params.uniqueName
        }?offset=${0}&sortBy=${sortBy}&category=${_category}`,
      )
    }
    else {
      history.replace(
        `/fanspot/${
          match.params.uniqueName
        }?offset=${0}&sortBy=${sortBy}&searchWord=${searchWord}&category=${_category}`,
      )
    }
  }

  useEffect(() => {
    const fetchCategory = async () => {
      const result = await fetchArticleCategoryList()

      setCategoryList(result)
    }

    fetchCategory()
  }, [])

  useEffect(() => {
    if (authStore.currentUser && articleStore.artistInfo) {
      authStore.fetchLike(
        articleStore.artistInfo._id,
        authStore.currentUser._id,
      )
    }
  }, [articleStore.artistInfo, authStore.currentUser])

  useEffect(() => {
    if (query.offset) setOffset(Number(query.offset))
    if (query.sortBy) setSortBy(query.sortBy)
    if (query.category) {
      setCategory(query.category)
    }
    setSearchWord(query.searchWord)
  }, [query.offset, query.sortBy, query.searchWord, query.category])

  useEffect(() => {
    const options = {
      uniqueName: match.params.uniqueName,
      limit: 10,
      sortBy,
      offset,
    }

    if (searchWord) {
      options.__searchWord = searchWord
    }

    if (category) {
      if (category === 'artist') {
        options.writerType = 'artist'
      }
      else {
        if (category !== 'all') {
          options.articleCategoryId = category
        }
        delete options.writerType
      }
    }

    fetchArtistArticleList(options)
  }, [
    offset,
    sortBy,
    isFromArtist,
    match.params.uniqueName,
    searchWord,
    category,
  ])

  useEffect(() => {
    if (!currentUser) {
      window.localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [currentUser])

  useEffect(() => {
    const fetchPointData = async () => {
      const result = await fetchArtistFanSpotPointInfo({
        uniqueName: match.params.uniqueName,
      })

      if (result) {
        setArtistFanSpotPointInfo(result)
      }

      const result2 = await fetchInplamByUniqueName({
        uniqueName: match.params.uniqueName,
      })

      if (result2) {
        setArtistInplamInfo(result2)
      }
    }

    fetchPointData()
  }, [match.params.uniqueName])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (artistInplamInfo?.isInplamOpen) {
    return (
      <InPlamArtistTemplate
        isLike={likeInfo && likeInfo.isLike}
        uniqueName={match.params.uniqueName}
        artistInfo={artistInplamInfo}
        artistArticleList={artistArticleList}
        popularArtistArticleList={popularArtistArticleList}
        artistArticleListCount={artistArticleListCount}
        offset={offset}
        handlePagination={handlePagination}
        sortBy={sortBy}
        handleChangeSortBy={handleChangeSortBy}
        isFromArtist={isFromArtist}
        // toggleFromArtist={toggleFromArtist}
        currentPath={DOMAIN_URL + match.url}
        artistFanSpotPointInfo={artistFanSpotPointInfo}
        currentUser={currentUser}
        searchWord={searchWord}
        handleChangeSearchWord={handleChangeSearchWord}
        categoryList={categoryList}
        category={category}
        handleChangeCategory={handleChangeCategory}
      />
    )
  }
  return (
    <>
      {/* {
        isShowLoginPopup && (
          <LoginPopup handleClose={() => setIsShowLoginPopup(false)} />
        )
      } */}
      <InPlamArtistOpenTemplate
        isLike={likeInfo && likeInfo.isLike}
        uniqueName={match.params.uniqueName}
        artistInfo={artistInplamInfo}
        artistArticleList={artistArticleList}
        popularArtistArticleList={popularArtistArticleList}
        artistArticleListCount={artistArticleListCount}
        offset={offset}
        handlePagination={handlePagination}
        sortBy={sortBy}
        handleChangeSortBy={handleChangeSortBy}
        isFromArtist={isFromArtist}
        // toggleFromArtist={toggleFromArtist}
        currentPath={DOMAIN_URL + match.url}
        artistFanSpotPointInfo={artistFanSpotPointInfo}
        currentUser={currentUser}
        searchWord={searchWord}
        handleChangeSearchWord={handleChangeSearchWord}
        categoryList={categoryList}
        category={category}
        handleChangeCategory={handleChangeCategory}
        needAuth={() => setIsShowLoginPopup(true)}
        isLoading={isLoading}
      />
    </>
  )
}

export default inject(
  'authStore',
  'articleStore',
  'inplamStore',
  'loadingStore',
)(observer(InPlamArtistPage))
