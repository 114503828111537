import { Caption1, Caption2, Flex, H6 } from '@components/atoms'
import React from 'react'
import { colors } from '@colors/'
import styled from 'styled-components'
import {
  converseCommentUnitEng,
  convertToPricingComma,
  dateFromString,
  dateTodayYMDMM,
} from '@utils/format'
import {
  auth_artist_img,
  upload_photo_img,
  notice_img,
  sns_instagram,
  sns_twitter,
  sns_youtube,
} from '@images/'
import { STORAGE_URL } from '@consts'
import qs from 'qs'
import {
  comments_fill_24,
  default_profile_img,
  plamin_circle_logo_img,
  star_fill_24,
  thumbup_fill_24_off,
  thumbup_fill_24_on,
} from '@images/index'
import Image from './Image'

const SnsIconComponenet = ({ text }) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g

  if (!text) {
    return null
  }

  const icons = text.split(urlRegex).map(url => {
    const query = qs.parse(url, {
      ignoreQueryPrefix: true,
      // 문자열 맨 앞의 ?를 생력
    })

    if (url.indexOf('youtube') !== -1) {
      // if (url.indexOf('youtube') !== -1) {
      if (query[Object.keys(query)[0]]) {
        return <SnsImg src={sns_youtube} />
      }
    }
    if (url.indexOf('youtu.be') !== -1) {
      // if (!query[Object.keys(query)[0]] && url.indexOf('youtu.be') !== -1) {

      return <SnsImg src={sns_youtube} />
    }

    // 인스타그램 링크 처리 (임베드 공유 복사)
    if (
      url.includes('instagram.com/p/')
      && url.includes('?utm_source=ig_embed')
    ) {
      return <SnsImg src={sns_instagram} />
    }

    // 인스타그램 링크 처리 (임베드 링크 복사)
    if (url.includes('instagram.com/p/')) {
      return <SnsImg src={sns_instagram} />
    }

    // X (구 Twitter) 링크 처리
    if (
      url.includes('twitter.com')
      || (url.includes('x.com') && url.includes('?ref_src=twsrc'))
    ) {
      return <SnsImg src={sns_twitter} />
    }

    // X (구 Twitter) 링크 처리
    if (url.includes('twitter.com') || url.includes('x.com')) {
      return <SnsImg src={sns_twitter} />
    }

    return null
  })

  return icons.filter(val => !!val)?.[0] || null
}

const TotalInPlamListItem = ({ item, onClick, handleArticleLike }) => {
  if (item && !item.isNotice) {
    return (
      <InPlamListItem
        key={item._id}
        onClick={
          () => {
            onClick(item)
          // if (item.isNotice) {
          //   history.push(`/fanspot/total/${item._id}?offset=0&sortBy=createdAt`)
          // }
          // else {
          //   history.push(
          //     `/fanspot/${item.uniqueName}/${item._id}?offset=0&sortBy=createdAt`,
          //   )
          // }
          }
        }
      >
        <Flex
          style={
            {
              marginBottom: '9px',
              justifyContent: 'space-between',
            }
          }
        >
          <Flex align={'center'}>
            {
              item.isNotice ? (
                <ArtistText
                  style={
                    {
                      color: '#ea4653',
                      border: 'solid 1px #ea4653',
                      cursor: 'pointer',
                      borderRadius: 2,
                      fontFamily: 'NotoSansCJKkr-Medium',
                      padding: '2px 0',
                    }
                  }
                // onClick={
                //   () =>
                //     history.push(
                //       `/fanspot/total/${item._id}?offset=0&sortBy=createdAt`,
                //     )
                // }
                >
                공지사항
                </ArtistText>
              ) : (
                <ArtistText>{item.artistName}</ArtistText>
              )
            }
            {
item?.artistInfo?.isInplamFavorite && !item.isNotice && (
                <Image src={star_fill_24} size={['12px', '12px']} />
              )
            }
          </Flex>
          <Caption2 color={'#949494'} type={'Regular'}>
            {dateFromString(item?.createdAt)}
          </Caption2>
        </Flex>
        <Flex align={'center'}>
          <Image
            src={
              item?.writerInfo?.profileImage64Path
                ? `${STORAGE_URL}${item?.writerInfo?.profileImage64Path}`
                : default_profile_img
            }
            size={['24px', '24px']}
            style={
              {
                marginRight: '8px',
                borderRadius: '50%',
                border: `solid 0.5px ${colors.inActive}`,
              }
            }
          />
          {
            <WriterText color={'#949494'}>
              {
item?.isNotice
  ? '플램 PLAM'
  : item?.isAnonymous
    ? '익명'
    : item.writerId === item?.artistInfo?.userId
      ? item?.artistInfo?.name
      : item.writerInfo && item.writerInfo.nickname
              }
            </WriterText>
          }
        </Flex>
        <Flex type={'column'} style={{ marginTop: '2px' }}>
          <TitleText>{item.title}</TitleText>
          <BodyText>{item.text}</BodyText>
        </Flex>
        <Flex style={{ gap: '12px', marginTop: '8px' }}>
          <Flex align={'center'}>
            <Image
              src={item?.isLike ? thumbup_fill_24_on : thumbup_fill_24_off}
              size={['20px', '20px']}
              style={
                {
                  marginRight: '2px',
                  cursor: 'pointer',
                }
              }
              // onClick={
              //   () => {
              //     handleArticleLike(item)
              //   }
              // }
            />
            <Caption1 color={'#646464'}>
              {
item?.likeCount > 100000
  ? '10만+'
  : convertToPricingComma(item?.likeCount)
              }
            </Caption1>
          </Flex>
          <Flex>
            <Image
              src={comments_fill_24}
              size={['20px', '20px']}
              style={
                {
                  marginRight: '2px',
                }
              }
            />
            <Caption1 color={'#646464'}>
              {
item?.likeCount > 100000
  ? '10만+'
  : convertToPricingComma(item?.commentCount)
              }
            </Caption1>
          </Flex>
        </Flex>
      </InPlamListItem>
    )
  }
  if (item && item.isNotice) {
    return (
      <InPlamListItem
        key={item._id}
        onClick={
          () => {
            onClick(item)
          // if (item.isNotice) {
          //   history.push(`/fanspot/total/${item._id}?offset=0&sortBy=createdAt`)
          // }
          // else {
          //   history.push(
          //     `/fanspot/${item.uniqueName}/${item._id}?offset=0&sortBy=createdAt`,
          //   )
          // }
          }
        }
      >
        <Flex
          style={
            {
              marginBottom: '9px',
              justifyContent: 'space-between',
            }
          }
        >
          <Flex align={'center'}>
            {
              item.isNotice ? (
                <Caption1 color={'#e13240'}>공지</Caption1>
              ) : (
                <ArtistText>{item.artistName}</ArtistText>
              )
            }
            {
item?.artistInfo?.isInplamFavorite && !item.isNotice && (
                <Image src={star_fill_24} size={['12px', '12px']} />
              )
            }
          </Flex>
          <Caption2 color={'#949494'} type={'Regular'}>
            {dateFromString(item?.createdAt)}
          </Caption2>
        </Flex>
        <Flex align={'center'}>
          <Image
            src={
              item.isNotice
                ? plamin_circle_logo_img
                : item?.writerInfo?.profileImage64Path
                  ? `${STORAGE_URL}${item?.writerInfo?.profileImage64Path}`
                  : default_profile_img
            }
            size={['24px', '24px']}
            style={
              {
                marginRight: '8px',
                borderRadius: '50%',
                border: `solid 0.5px ${colors.inActive}`,
              }
            }
          />
          {
            <WriterText color={'#949494'}>
              {
item?.isNotice
  ? '플램 PLAM'
  : item?.isAnonymous
    ? '익명'
    : item?.writerInfo?.name
              }
            </WriterText>
          }
        </Flex>
        <Flex type={'column'} style={{ marginTop: '2px' }}>
          <TitleText>{item.title}</TitleText>
          <BodyText>{item.text}</BodyText>
        </Flex>
      </InPlamListItem>
    )
  }
  return <></>
}

export default TotalInPlamListItem

const ItemBox = styled(Flex)`
  margin-left: -20px;
  width: calc(100% + 40px);
  padding: 16px 20px;
  border-bottom: solid 1px ${colors.whitef4};
  box-sizing: border-box;
  height: 106px;
  cursor: pointer;
  flex-direction: column;
`

// const TitleText = styled(Caption1)`
//   width: fit-content;
//   max-width: 100%;
//   text-align: left;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 1;
//   -webkit-box-orient: vertical;
//   word-wrap: break-word;
// `

const CommentCountBox = styled(Flex)`
  flex-direction: column;
  max-width: 48px;
  min-width: 48px;
  height: 48px;
  padding: 6px 9px;
  background-color: ${colors.whitef4};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

const ThumbnailBox = styled(Flex)`
  flex-direction: column;
  max-width: 48px;
  min-width: 48px;
  height: 48px;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
`

const FromArtistImg = styled.img`
  width: 12px;
  height: 12px;
`

const SnsImg = styled.img`
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  object-fit: cover;
  margin-left: 8px;
`

const InPlamListItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  /* height: 34px; */
  box-sizing: border-box;
  flex-direction: column;
  padding: 12px 14px;
  background: #fff;
  border-radius: 12px;
`

const ArtistText = styled(Caption2)`
  font-family: NotoSansCJKkr-Bold;
  /* text-align: left; */
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`
const WriterText = styled(Caption1)`
  font-family: NotoSansCJKkr-Bold;
  /* text-align: left; */
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`

const TitleText = styled(H6)`
  width: 100%;
  font-family: NotoSansCJKkr-Medium;
  text-align: left;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`
const BodyText = styled(H6)`
  width: 100%;
  font-family: NotoSansCJKkr-Regular;
  text-align: left;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`
